import React, { useEffect } from 'react';
import { getBezierPath } from 'reactflow';
import Button from '@mui/material/Button';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import IconButton from '@mui/material/IconButton';
import { useReactFlow } from 'reactflow';
const foreignObjectSize = 40;



export default function CustomEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}) {
    const { setEdges } = useReactFlow();

    const onEdgeClick = (evt, id) => {
        evt.stopPropagation();
        setEdges((eds) => eds.filter((e) => e.id !== id));
    };

    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={labelX - foreignObjectSize / 2}
                y={labelY - foreignObjectSize / 2}
            >
                <IconButton onClick={(event) => onEdgeClick(event, id)} >
                    <CancelRoundedIcon />
                </IconButton>
            </foreignObject>
        </>
    );
}
