import React, { memo, useContext } from 'react';
import { Handle } from 'reactflow';
import { Position } from 'reactflow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { GlobalConnentContext } from '../Context/GlobalConnentContext'

function DisplayNode({ id, data }) {
    const { GlobalConnent, setGlobalConnect } = useContext(GlobalConnentContext)
    const handleId = 'dis';
    const inputAssigned = GlobalConnent.input[id + '_' + handleId];
    const inputContent = GlobalConnent.content[Array.isArray(inputAssigned) ? inputAssigned[0] : inputAssigned];
    return (
        <Paper
            elevation={3}
            display="flex"
            sx={{
                border: 0,
                borderRadius: 2,
                bgcolor: "#f5f5f6",
                color: 'grey.300',
                position: "relative",

            }}
        >
            <TextField
                id="outlined-read-only-input"
                variant="standard" // <== changed this
                margin="normal"
                multiline
                value={inputContent ? inputContent : ''}
                InputProps={{
                    readOnly: true,
                    disableUnderline: true, // <== added this
                    inputProps: {
                        style: { textAlign: "center" },
                    }
                }}
                sx={{
                    width: 80,

                }}
            />
            <Handle
                type="target"
                position={Position.Left}
                id={handleId}
            />
        </Paper>
    );
}

export default memo(DisplayNode);