import React, { memo, useContext, useEffect, useCallback, useMemo } from 'react';
import { Handle, Position } from 'reactflow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import PercentIcon from '@mui/icons-material/Percent';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import { GlobalConnentContext } from '../Context/GlobalConnentContext'



function OperatorNode({ id, data }) {

    const { GlobalConnent, setGlobalConnect } = useContext(GlobalConnentContext)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [operator, setOperator] = React.useState("+");

    const inputAssigned = GlobalConnent.input[id + '_' + 'in'];
    
    const inputContent = useMemo(()=>{return !!inputAssigned ? inputAssigned.map(element => {
            return parseFloat(GlobalConnent.content[element])
        }) : []},[inputAssigned,GlobalConnent])
   


    const res = useMemo(
        () => {
            console.log('res update');
            return inputContent.length !== 0 ?
                inputContent.reduce(
                    (x, y) => {
                        switch (operator) {
                            case "+": return x + y;
                            case "-": return x - y;
                            case "*": return x * y;
                            case "/": return x / y;
                        }

                    }, operator === "+" || operator === "-" ? 0 : 1
                ) : undefined;
        }
        , [operator, inputContent]
    )
    useEffect(
        () => {
            setGlobalConnect(
                nds => {
                    let stateCopy = Object.assign({}, nds);
                    stateCopy.content[id + '_' + 'out'] = res;
                    return stateCopy
                }
            )
        }
        , [operator, res]
    )



    const handleClick = useCallback(
        (event) => {
            setAnchorEl(event.currentTarget);
        }
        , []
    );

    const handleClose = useCallback(
        () => {
            setAnchorEl(null);
        }
        , []
    );

    const clickClose = useCallback(
        (op) => {
            return (() => {
                setAnchorEl(null);
                setOperator(op);
            })
        }
        , []
    );

    const open = Boolean(anchorEl);

    const customIcon = (operator) => {
        switch (operator) {
            case "+": return <AddIcon />;
            case "*": return <ClearOutlinedIcon />;
            case "-": return <RemoveIcon />;
            case "/": return <PercentIcon />;
        }
    }

    return (
        <Paper
            elevation={3}
            display="flex"
            sx={{
                border: 0,
                borderRadius: 10,
                bgcolor: "#f5f5f6",
                color: 'grey.300',
                position: "relative",

            }}>

            <IconButton size="large" onClick={handleClick}>
                {customIcon(operator)}
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}

            >
                <Box p={2}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <IconButton size="large" onClick={clickClose("+")}>
                            <AddIcon />
                        </IconButton>
                        <IconButton size="large" onClick={clickClose("*")}>
                            <ClearOutlinedIcon />
                        </IconButton>
                        <IconButton size="large" onClick={clickClose("-")}>
                            <RemoveIcon />
                        </IconButton>
                        <IconButton size="large" onClick={clickClose("/")}>
                            <PercentIcon />
                        </IconButton>

                    </Stack>
                </Box>
            </Popover>

            <Handle
                type="target"
                position={Position.Left}
                id='in'
            />
            <Handle
                type="source"
                position={Position.Right}
                id='out'
            />
        </Paper>
    );
}

export default memo(OperatorNode);