import React, { useContext, memo, useCallback, useEffect } from 'react';
import { Handle } from 'reactflow';
import { Position } from 'reactflow';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import { GlobalConnentContext } from '../Context/GlobalConnentContext'



function ConstantNode({ id, data }) {
    const handleId = 'cons';

    const { GlobalConnent, setGlobalConnect } = useContext(GlobalConnentContext)

    const [textInput, setTextInput] = useState('');
    const handleTextInputChange = event => {
        setTextInput(event.target.value);
    }

    useEffect(
        () => {
            setGlobalConnect(
                nds => {
                    let stateCopy = Object.assign({}, nds);
                    stateCopy.content[id + '_' + handleId] = textInput;
                    return stateCopy
                }
            )
        }
        , [textInput]
    )


    return (
        <Paper
            elevation={3}
            display="flex"
            sx={{
                border: 0,
                borderRadius: 2,
                bgcolor: "#f5f5f6",
                color: 'grey.300',
                position: "relative",

            }}>

            <TextField
                autoComplete="off"
                multiline
                type='number'
                id="outlined-basic"
                label="Num"
                variant="outlined"
                value={textInput}
                onChange={handleTextInputChange}
                sx={{
                    width: 100,
                }}
            />
            <Handle
                type="source"
                position={Position.Right}
                id={handleId}
            />
        </Paper>
    );
}

export default memo(ConstantNode);
