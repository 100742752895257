import React, { useState, useRef, useCallback } from 'react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    Background,
    useNodesState,
    useEdgesState,
    Controls,
} from 'reactflow';
import 'reactflow/dist/style.css';
import Box from '@mui/material/Box';
import ConstantNode from './Nodes/ConstantNode';
import DisplayNode from './Nodes/DisplayNode';
import OperatorNode from './Nodes/OperatorNode';
import { GlobalConnentProvider } from './Context/GlobalConnentContext';
import ButtonEdge from './Edges/ButtonEdges';


const initialNodes = [];

let id = 0;
const getId = () => `dndnode_${id++}`;
const nodeTypes = {
    Constant: ConstantNode,
    Display: DisplayNode,
    Operator: OperatorNode
};
const edgeTypes = {
    buttonedge: ButtonEdge,
};







const DnDFlow = () => {

    //const { GlobalConnent, setGlobalConnect } = useContext(GlobalConnentContext)
    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    const onConnect = useCallback(
        (params) => {
            setEdges(
                (eds) => addEdge(
                    { ...params, type: 'buttonedge' }, eds)
            )
        },
        []
    );

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');

            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });
            const newNode = {
                id: getId(),
                type:type,
                position,
            };

            setNodes(
                (nds) => nds.concat(newNode)
            );
        },
        [reactFlowInstance]
    );

    return (
            <ReactFlowProvider>
                <GlobalConnentProvider>
                    <Box ref={reactFlowWrapper} style={{  height:"calc(100vh - 85px)"}}>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            onInit={setReactFlowInstance}
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                            fitView
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                        >
                            <Controls />
                            <Background color="#aaa" gap={16} />
                        </ReactFlow>
                    </Box>
                </GlobalConnentProvider>
            </ReactFlowProvider>
    );
};

export default DnDFlow;
