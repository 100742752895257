import React, { memo, useEffect, useMemo, useState } from "react";
import { useMemoObject } from "../Hooks/useMemo";
import { useEdges } from 'reactflow';

export const GlobalConnentContext = React.createContext();

export const GlobalConnentProvider = memo(({ children }) => {

    const [GlobalConnent, setGlobalConnect] = useState(
        {
            input: {},
            content: {}
        }
    );


    const context = useMemoObject(
        {
            GlobalConnent,
            setGlobalConnect
        }
    )

    const edges = useEdges();

    useEffect(
        () => {
            
            
            const inputMap = {};
            edges.forEach(element => {
                const exist=!!inputMap[element.target + '_' + element.targetHandle] ;
                inputMap[element.target + '_' + element.targetHandle] = exist?inputMap[element.target + '_' + element.targetHandle].concat([element.source+'_'+element.sourceHandle]):[element.source+'_'+element.sourceHandle];
            })
     
            setGlobalConnect(prev => { return { ...prev, input: inputMap } })
        }
        , [edges]
    )

    return (<GlobalConnentContext.Provider value={context}>
        {children}
    </GlobalConnentContext.Provider>)
}
)


